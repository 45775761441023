/* @tailwind base; */
@tailwind components;
@tailwind utilities;


.mantine-1avhz5g {
    color: #fff;
}
.mantine-g9emdi {
    height: 3rem;
}
.mantine-TextInput-label {
    color: #fff;
    margin-bottom: 5px;
    font-size: 14px;
    margin-top: 15px;

}
.mantine-Switch-label {
    color: #fff;
}
.iti--allow-dropdown {
    width: 100%;
}
.iti input, .iti input[type=text], .iti input[type=tel] {
    height: 48px !important;
    width: 100vh
}
.iti__selected-flag .iti__flag {
    box-shadow: none;
    background-color: transparent;
}

.mantine-Paper-root  {
    background-color: #1f1f1f;
}
